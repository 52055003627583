
import { defineComponent } from "vue";
import { Form, Field, ErrorMessage } from "vee-validate";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import * as Yup from "yup";
import ApiService from "@/core/services/ApiService";
import { VueCookieNext } from "vue-cookie-next";
import KTEditor from "@/layout/toolbar/QuillEditor.vue";
import { ElNotification } from 'element-plus';
export default defineComponent({
  name: "create-task",
  components: {
    Form,
    Field,
    ErrorMessage,
    KTEditor,
  },
  data() {
    return {
      entity: {
        division: "",
        district: "",
        sub_district: "",
        entity_description: "",
      },
      divisions: [],
      districts: [],
      subDistricts: [],
      empDistricts: [],
      loading: false,
      lists: [] as any,
      upazila_name_eng: "" as any,
      deadline: "" as any,
      grid_id: [] as any,
      employeeList: "" as any,
      name: "" as any,
      gridList: "" as any,
      roleByemployeeList: "" as any,
      rs_id: "" as any,
      rs_role_id: "" as any,
      upazila_id: [] as any,
      assigned_to_rs_id: "" as any,
      assigned_by_gis_id: "" as any,
      taskGroupLists: "" as any,
      taskGroupStepLists: "" as any,
      group_id: "" as any,
      mouzaList: "" as any,
      mouza_id: [] as any,
      district_id: "" as any,
      districtLists: "" as any,
      task_note: "" as any,
      roleLists: "" as any,
      entity_role_id: "" as any,
      taskGroupEmployeeList: "" as any,
      current_status: "1",
      task_type: "1",
      quillEditorId: 'task-create' as any,
      files: [] as any,
      inputFiles: [] as any,
      fileNameByUser: {} as any,
      fileName: [] as any,
      fileEvent:[] as any,
    };
  },
  async created() {
    await this.getTaskGroup();
    await this.getTaskGroupStep();
    await this.geoDistrict();
    await this.geoUpazila();
    await this.getDivision();
    await this.getDistrictForEmp();   
    this.assigned_by_gis_id = VueCookieNext.getCookie('_seip_employee_id');

  },
  methods: {
    async getEntityTypesRoles() {
      await ApiService.get(
        "entity_type_role/role/list")
        .then((response) => {
          this.roleLists = response.data.data;   
          console.log(this.entity_role_id) ;   
          this.getRoleEmployee(this.entity_role_id);
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async getTaskGroup() {
      await ApiService.get("configurations/task-group/list")
        .then((response) => {
          this.taskGroupLists = response.data.data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async getTaskGroupStep() {
      await ApiService.get("configurations/task-group/step")
        .then((response) => {
          this.taskGroupStepLists = response.data.data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async geoDistrict() {
      await ApiService.get(
        "configurations/geo_district/single-list")
        .then((response) => {

          this.districtLists = response.data.data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async geoUpazila() {
      await ApiService.get("configurations/geo_upazila/list?district="+this.district_id)
        .then((response) => {
          this.lists = response.data.data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async getRoleEmployee(role_id) {
      await ApiService.get("configurations/employee/roleList?role_id="+role_id)
        .then((response) => {
          this.roleByemployeeList = response.data.data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async getTaskGroupEmployee() {
      await ApiService.get("configurations/task-group/role_index?group_id="+this.group_id)
        .then((response) => {
          this.taskGroupEmployeeList = response.data.data;
          this.getRoleEmployee(this.taskGroupEmployeeList);
          if(this.group_id==4){
          this.getEntityTypesRoles();
          }
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },

    async getGrid() {
      console.log(this.upazila_id);
      await ApiService.get("configurations/geo_grid/list?district_id="+this.district_id)
        .then((response) => {
          this.gridList = response.data.data;
          this.geoUpazila();
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async getMouza() {
      await ApiService.get("configurations/geo_mouza/list?district_id="+this.district_id)
        .then((response) => {
          this.mouzaList = response.data.data;
          this.geoUpazila();
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async formSubmit() { 
      let editorElement = '' as any;
      editorElement = document.querySelector('#'+ this.quillEditorId +' .ql-editor');
      const task_note = editorElement.innerHTML;
      let formData = new FormData();

      for(var key in this.fileNameByUser){
          this.fileName.push(this.fileNameByUser[key]);
    }

      formData.set('group_id', this.group_id);
      formData.set('district_id', this.district_id);
      formData.set('upazila_id', this.upazila_id);
      formData.set('grid_id', this.grid_id);
      formData.set('mouza_id', this.mouza_id);
      formData.set('task_note', task_note);
      formData.set('assigned_to_rs_id', this.assigned_to_rs_id);
      formData.set('assigned_by_gis_id', this.assigned_by_gis_id);
      formData.set('deadline', this.deadline);
      formData.set('task_type', this.task_type);
      formData.set('current_status', this.current_status);
      formData.set('created_by', this.assigned_by_gis_id);
      formData.set('updated_by', this.assigned_by_gis_id);

      if (this.fileEvent && this.fileEvent.target && this.fileEvent.target.files && this.fileEvent.target.files.length > 0){
        const files= this.fileEvent.target.files;
        for (let i = 0; i < files.length; i++) {
          formData.append('files[]', files[i]);
        }
        formData.set('files', this.fileEvent);
        formData.set('fileNameByUser', this.fileName);
      }
      
      this.loading = true;
      await ApiService.post('configurations/task/save',formData)
      .then((response) => {
          this.loading = false;
          if(response.data.status=='success')
          {
            ElNotification({
              dangerouslyUseHTMLString: true,
              message:
              '<i class="fa fa-check" aria-hidden="true"></i> <b>Task has assigned successfully!</b>',
            });
            window.location.reload();        
          }
          else
          {
            ElNotification({
              dangerouslyUseHTMLString: true,
              message:
              '<i class="fa fa-warning" aria-hidden="true"></i> <b>Something Went Wrong!</b>',
            });
          }
          
      })
      .catch(({ response }) => {
        console.log(response);
      });
    },
    async getDivision() {
      await ApiService.get("geo/divisions")
        .then((response) => {
          this.divisions = response.data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async getDistrict() {
      await ApiService.get("geo/districts?division=" + this.entity.division)
        .then((response) => {
          this.districts = response.data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async getDistrictForEmp() {
      await ApiService.get("geo/districts")
        .then((response) => {
          this.empDistricts = response.data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async getSubDistrict() {
      await ApiService.get(
        "geo/upazilas?division=" +
        this.entity.division +
        "&district=" +
        this.entity.district
      )
        .then((response) => {
          this.subDistricts = response.data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    uploadFile(){
      let button = '' as any;
        button = document.querySelector('.fileInput');
      if (button) {
        button.click();
      }
    },
    onFileChange(e) {
      var files = e.target.files || e.dataTransfer.files;
      this.fileEvent= e;
      if (!files.length) return;
      this.inputFiles.push(files);
      this.createImage(files);
    },
    createImage(files) {
      var vm = this;
      for (var index = 0; index < files.length; index++) {
        let name = files[index].name;
        let type = name.split('.').pop();
        let size = files[index].size;
        size = size/10485760;
        var reader = new FileReader();
        reader.onload = function(event) {
          if(event.target){
            const imageUrl = event.target.result;
            let data = {
              src:imageUrl,
              name: name,
              type: type,
              size: size,
            };
            vm.files.push(data);
          }
           
        }
        reader.readAsDataURL(files[index]);
      }
      
    },
    removeImage(index) {
      this.files.splice(index, 1)
    },
  },
  setup() {
 
    return {
    };
  },
});
